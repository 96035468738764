<template>
  <div>
    <!-- Title and subtitle -->
    <h1 class="auth-title expired-title">{{ $t('EXPIRED.TITLE') }}</h1>
    <p class="auth-subtitle">{{ $t('EXPIRED.SUBTITLE') }}</p>

    <!-- Form -->
    <FormWrapper :send-form-data="validateData" class="page-form-wrapper">
      <template #form-content>

        <!-- Email -->
        <InputField :field-title="`${$t('EMAIL_ADDRESS')} *`"
                    :field-name="'email'"
                    :rules="'required'"
                    :type="'email'"
                    :value="email"
                    :placeholder="$t('EMAIL_ADDRESS')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                    :api-error-text="apiErrorText"
                    class="auth-form-element"/>
      </template>
      <template #button-submit>
        <button type="submit" class="pink-button auth-button-close button-send">
          {{ $t('EXPIRED.SEND') }}
        </button>
      </template>
    </FormWrapper>


    <div class="contact-admin-prompt">
      <p> {{ $t('EXPIRED.CONTACT_ADMIN') }} </p>
      <a class="link"> {{ $t('EXPIRED.ADMIN_EMAIL') }}  </a>
    </div>

  </div>
</template>

<script>
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { FORGET_PASSWORD_REQUEST } from '@/store/modules/auth/actions'

export default {
  name: 'ExpiredPassword',
  components: {
    FormWrapper,
    InputField,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const email = route.params.email

    function validateData(data) {
      store.dispatch(FORGET_PASSWORD_REQUEST, { email: data.email }).then(response => {
        if (response) {
          router.push({ name: ROUTE_NAMES_AUTH.RESET_LINK_SENT, params: { email: data.email } })
        }
      })
    }

    return {
      ROUTE_NAMES_AUTH,
      authError: '',
      apiErrorText: '', // TODO: Get from the API.
      email,
      // Form
      validateData,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.expired-title {
  margin-bottom: rem(14);
}

.button-send {
  min-width: rem(130);
}

.auth-button-close{
  margin-top: rem(20);
}

.contact-admin-prompt{
  margin-top: rem(20);
  display: flex;
  gap: rem(10);
}

.link{
  color: var(--blue_light);
  font-weight: 800;
}
</style>
